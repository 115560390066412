import React from 'react';
import { Input } from 'antd';

const input = ({ label, className, ...otherProps }) => {
  return (
    <div className={`custom-input ${className}`}>
      {label && <h6 className="form-label">{label}</h6>}
      <Input className="input-field" {...otherProps} />
    </div>
  )
}

export default input;