import React from 'react';
import { useState, useEffect } from 'react'
import axios from 'axios'
import { Form } from 'react-bootstrap';

import Pdf from '../../../../src/assests/files/Information-on-tax-exemptions-for-selected-imports-into-Indonesia.pdf';

const ItemCategoriesDropdown = (props) => {
  const [itemCategoriesLoading, setLoading] = useState(true)
  const [itemCategories, setItemCategories] = useState([])

  useEffect(() => {
    const getItemCategories = async () => {

    const response = await axios.get(`https://api.janio.asia/api/data/item-categories/`)
    setItemCategories(response.data)
    setLoading(false)
    }
    getItemCategories()
  }, []);
  let label = (
    <Form.Label className='d-block text-left mb-0'>
      <p className='d-inline'>Item Category </p>
      <p className='d-inline' style={{color:'red'}}>*</p>
      <p className='d-inline'> :</p>
    </Form.Label>
  )
  let formControl = (
    itemCategoriesLoading 
    ? (
    <Form.Control as="select" onChange={props.change} value={props.value}>
      <option>loading Item Categories..</option>
    </Form.Control>
    )
    :(
      <Form.Control as="select" onChange={props.change} value={props.value}>
        {itemCategories.map(itemCategory => {
          return <option>{itemCategory}</option>
        })}
      </Form.Control>
    ))
  let extra = (
    props.value.includes('Covid 19 tax-exempted items')
    ? <p className='mb-1'>In light of Covid-19 pandemic, the Indonesian government has provided for tax exemptions for a list of products. <a href={Pdf} target="_blank" rel="noopener noreferrer">Find out more here.</a></p>
    : null
  )
  
  return (
    <Form.Group className='w-100 d-flex flex-column align-items-start' controlId="formBasicEmail">
      {label}
      <Form.Text className="d-block text-left text-muted mt-1" style={{fontSize: 15}}>
        {extra}
      </Form.Text>
      {formControl}
    </Form.Group>
  );

}

export default ItemCategoriesDropdown