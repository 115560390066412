import React from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import logo from "../../../assests/images/janio-no-tag-white.png";

import "./navigation.css";

const navbar = (props) => {
  let parts = window.location.pathname.split("/");
  const lastSegment = parts.pop() || parts.pop();

  // navbar links
  const links = [
    {
      className: "visible-mobile",
      name: "Register",
    },
    {
      className: "visible-mobile",
      href: "faq",
      name: "FAQ",
    },
    {
      href: "create",
      name: "Create Orders",
    },
    {
      href: "mps-order",
      name: "Create MPS Order"
    },
    {
      href: "return",
      name: "Create Return Orders",
    },
    {
      href: "view",
      name: "View Orders",
    },
    {
      href: "track",
      name: "Track Orders",
    },
    {
      href: "cancel",
      name: "Cancel Orders",
    },
    {
      href: "simulator",
      name: "Tracking Simulator",
    },
    {
      href: "labels",
      name: "Labels",
    },
    {
      href: "locations",
      name: "Locations",
    },

    {
      href: "service-finder",
      name: "Service Finder",
    },

  ];

  const navLinks = links.map(({ className = "", href, name }) => (
    <Nav.Link
      key={name}
      className={className}
      as={Link}
      to={`/${href}`}
      active={lastSegment === href}
    >
      {name}
    </Nav.Link>
  ));

  return (
    <aside className="col-lg-2 col-xxl-1 doc-sidebar">
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="dark"
        className="navbar-vertical sticky"
      >
        <button
          type="button"
          className="sidebar-toggler navbar-toggler"
          onClick={props.toggleSidebar}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="mobile-nav">
          <Navbar.Brand>
            <a href="https://janio.asia/">
              <img src={logo} className="header-logo" alt="Janio logo" />
            </a>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        </div>
        <Navbar.Collapse>
          <Nav className="nav">{navLinks}</Nav>
        </Navbar.Collapse>
      </Navbar>
    </aside>
  );
};

export default navbar;
