import React from 'react';
import { Button } from 'react-bootstrap';

const SubmitButton = (props) => {
  let submitButton = props.loading
      ? <Button block variant={props.variant} className='pt-3'  disabled onClick={props.onClick}> <h5>{props.loadingText}</h5></Button>
      : <Button block variant={props.variant} className='pt-3' onClick={props.onClick}> <h5>{props.submitText}</h5></Button>

  return submitButton
}

export default SubmitButton