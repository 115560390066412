import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Table } from 'react-bootstrap';
import axios from 'axios'

import {PageLayout, RequestExample, ResponseExample, TestResponse, RequestInputs} from '../components/pageSections';
import RequestParams from '../components/UI/tables/requestParams';
import {trackingDesc} from '../utils/info';
import TerminalText from '../components/terminalText/terminal';
import Input from '../components/UI/input/stdInput';
import SubmitButton from '../components/UI/buttons/submitButton';
import {publicStatusV2} from '../utils/data';
import {ROOT_URL} from '../utils/urls';

const renderPublicStatuses = () => (
  <div>
    <p className='font-weight-bold text-left ml-2 mt-4'>Tracking Statuses</p>
    <Table>
      <tbody>
        {publicStatusV2.map(row=>(
          <tr key={row} className='d-flex'>
            <td className='text-left w-100'>
            <p className='text-primary'>{row}</p>
              </td>
          </tr>
        ))}
      </tbody>
    </Table>
  </div>
)

const Track = (props) => {
  const didMount = useRef(false)
  const [trackingNos, setTrackingNos] = useState('')
  const [flatten, setFlatten] = useState('false')
  const [getRelated, setGetRelated] = useState('false')
  const [loading, setLoading] = useState(false)
  const [clicked, setClicked] = useState(false)
  const [resp, setResponse] = useState(null)
  const [showResponse, setShowResponse] = useState(false)
  const responseExamples = [
    {status: 'Success', badgeVariant: 'success', statusCode: 200, terminalText: 'trackv2Success'},
    {status: 'Success (Flattened)', badgeVariant: 'success', statusCode: 200, terminalText: 'trackv2SuccessFlat'},
  ]
  let payload = {
    get_related_updates: getRelated === 'true',
    flatten_data: flatten === 'true',
    tracking_nos: trackingNos.replace(' ', '').split(',')
  }
  const method = 'POST'
  const endPt = `${ROOT_URL}/v2/tracker/query-by-tracking-nos/`
  const element = document.getElementById('responseContent')
  if (element) {
    element.scrollIntoView({block:'center', behavior:'smooth'})
  }

  useEffect(() => {
    const track = async () => {
      try {
        const response = await axios.post(endPt,
        payload, { headers: { 'Content-Type': 'application/json'} }
        )
        setResponse({data: response.data, hasError: false, statusCode: response.status})
        setShowResponse(true)
      }
      catch(e) {
        setResponse({data: e.response.data, hasError: true, statusCode: e.response.status})
        setShowResponse(true)
      }
      setLoading(false)
    }
    // only use the effect on update not on mount
    if (didMount.current) {
      setLoading(true)
      track()
    }
    else {
      didMount.current = true
    }
  }, [clicked])

  let inputs = (
    <RequestInputs title='Track Orders API Testing'>
      <div className='border rounded border-info px-3 pt-2 mb-2'>
        <h5>Track Orders Request Params</h5>
        <Input label='Tracking Numbers' type='text' placeholder='e.g. trackingnumber1,trackingnumber2' 
                extra='If entering multiple tracking numbers, separate them with `,`' change={(event) => {setTrackingNos(event.target.value); setShowResponse(false)}} value={trackingNos}/>
        <Input label='Get Related Updates' optional select options={['true', 'false']} change={(event) => {setGetRelated(event.target.value); setShowResponse(false)}} value={getRelated}/>
        <Input label='Flatten Data' optional select options={['true', 'false']} change={(event) => {setFlatten(event.target.value); setShowResponse(false)}} value={flatten}/>
      </div>
      <SubmitButton loading={loading} variant='success' onClick={() => {setClicked(!clicked)}} submitText='Test Track Order' loadingText='fetching tracking info...'/>
    </RequestInputs>
  )

  let content = (
    <>
      <RequestExample title='Track Orders' method={method} badgeVariant='success' uri='/v2/tracker/query-by-tracking-nos/'>
        <TerminalText text='trackEg'/>
        <RequestParams params={trackingDesc}/>
        {renderPublicStatuses()}
      </RequestExample>
      <ResponseExample examples={responseExamples}/>
      {showResponse && <TestResponse statusCode={resp.statusCode} req={payload} resp={resp.data} variant={resp.hasError ? 'danger' : 'success'} endPoint={`${method} ${endPt}`}/>}
    </>
  )

  return (
    <PageLayout left={content} right={inputs}/>
  )
}

export default Track