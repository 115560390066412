import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";

import Header from "./containers/Header";
import Main from "./containers/Main";
import Labels from "./containers/Labels";
import Register from "./containers/Register";
import Track from "./containers/Track";
import Simulator from "./containers/TrackingSimulator";
import View from "./containers/View";
import Create from "./containers/Create";
import Return from "./containers/Return";
import Faq from "./containers/Faq";
import Location from "./containers/Location";
import Cancel from "./containers/Cancel";
import ServiceFinder from "./containers/ServiceFinder";
import CreateMpsOrder from "./containers/CreateMpsOrder";

const App = (props) => {
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
  const toggleSidebar = () => setSidebarCollapsed(!isSidebarCollapsed);
  const isSidebarCollapsedClass = isSidebarCollapsed ? "is-sidebar-collapsed" : "";
  

  return (
    <div className="App">
      <div className={`container-fluid ${isSidebarCollapsedClass}`}>
        <Header />
        <Main toggleSidebar={toggleSidebar}>
          <Switch>
            <Route path="/create" component={Create} />
            <Route path="/mps-order" component={CreateMpsOrder} />
            <Route path="/return" component={Return} />
            <Route path="/labels" component={Labels} />
            <Route path="/track" component={Track} />
            <Route path="/cancel" component={Cancel} />
            <Route path="/simulator" component={Simulator} />
            <Route path="/view" component={View} />
            <Route path="/faq" component={Faq} />
            <Route path="/locations" component={Location} />
            <Route path="/service-finder" component={ServiceFinder} />
            <Route path="/" component={Register} />
          </Switch>
        </Main>
      </div>
    </div>
  );
};

export default App;
