import { useState, useEffect } from 'react';
import { JANIO_MAIN_API_URL } from '../utils/janioMainApi';
import axios from 'axios';

const useCountries = () => {
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const getCountries = async () => {
      const response = await axios.get(`${JANIO_MAIN_API_URL}/data/places/`);
      setCountries(response.data);
    }
    getCountries();
  }, []);

  return countries;
}

export default useCountries;