import { useState, useEffect } from 'react';
import { JANIO_MAIN_API_URL } from '../utils/janioMainApi';
import axios from 'axios';

const useServiceDef = (secretKey, additionalFilters) => {
  const [isServiceLoading, setLoading] = useState(true);
  const [serviceDefinitions, setServiceDefinitions] = useState([]);
  const filtersStringify = JSON.stringify(additionalFilters);

  useEffect(() => {
    if (secretKey) {
      const getServiceDefinitions = async () => {
        const response = await axios.get(`${JANIO_MAIN_API_URL}/order/service-definitions/`,
          {
            params: {
              secret_key: secretKey,
              ...additionalFilters
            }
          }
        )
        setServiceDefinitions(response.data);
        setLoading(false);
      }
      getServiceDefinitions();
    }
  }, [secretKey, filtersStringify]);

  return [serviceDefinitions, isServiceLoading];
}

export default useServiceDef;