import React from 'react';
import { Badge } from 'react-bootstrap';

const RequestExample = (props) => {
  const developmentEndpoint = `https://api.int.janio.asia/api${props.uri}`;
  const productionEndpoint = `https://api.janio.asia/api${props.uri}`;

  return (
    <div className="request-body">
      <div className="d-flex">
        <h4 className="request-body__title">
          {props.title}{" "}
          <Badge variant={props.badgeVariant}>{props.method}</Badge>
        </h4>
        <h6 className="text-muted pt-2">{props.uri}</h6>
      </div>
      <h5 className="font-weight-bold text-left">Request Example</h5>
      <p className="text-left mb-0">
        Development Endpoint:{" "}
        <a
          className="text-primary d-inline font-weight-lighter"
          href={developmentEndpoint}
        >
          {developmentEndpoint}
        </a>
      </p>
      <p className="text-left mt-0">
        Production Endpoint:{" "}
        <a
          className="text-primary d-inline font-weight-lighter"
          href={productionEndpoint}
        >
          {productionEndpoint}
        </a>
      </p>
      {props.children}
    </div>
  );
};

export default RequestExample;
