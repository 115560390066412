import React from "react";
import "./pageLayout.css";

const PageLayout = (props) => {

  return (
    <article className="col-lg-10 col-xxl-11 doc-content">
      <article className="col-md-8 col-xxl-7 doc-content__left">{props.left}</article>
      <aside className="col-md-4 col-xxl-4 doc-content__right">
        <div className="sticky">{props.right}</div>
      </aside>
    </article>
  );
};

export default PageLayout;
