import React, { Component } from 'react';
import {Button, Alert} from 'react-bootstrap';
import axios from 'axios';

import {ROOT_URL} from '../utils/urls';
import StdInput from '../components/UI/input/stdInput';

class Register extends Component {

  state = {
    isValid: false,
    loading: false,
    registered: false,
    registrationError: true,
    user: {
      added_by_admin: true,
      email: '',
      password: '',
      cfmPassword: ''
    },
    resMessage: []
  }

  registerShipper = () => {
    this.setState({loading: true})
    if (this.state.user.password !== this.state.user.cfmPassword) {
      this.setState({resMessage: ['Please ensure that the Password and Confirm Password entered are identical.']})
      this.setState({ registered: true })
      this.setState({loading: false})
      return false;
    };
    axios.post(ROOT_URL + '/admin/register-account/', this.state.user)
      .then(response => {
        this.setState({ loading: false})
        localStorage.setItem('secretKey',response.data.secret_key)
        this.setState({ registered: true })
        this.setState({registrationError: false})
      }).catch(error => {
        this.setState({loading: false})
        let errors = []
        if (error.response.data.email) {
            errors.push('Email - '+error.response.data.email[0])
        };
        if (error.response.data.password) {
          errors.push('Password - '+error.response.data.password[0])
        };
        this.setState({resMessage: errors})
        this.setState({ registered: true })
      });
  };

  inputChangedHandler = (event, inputIdentifier) => {
    const updatedUserForm = {
      ...this.state.user
    };
    updatedUserForm[inputIdentifier]= event.target.value;
    this.setState({user: updatedUserForm})
    // check validity
    // update validity
  };

  render() {
    // let active_status = this.state.loading ? disabled : active;
    let submitButton = this.state.loading
      ? <Button variant='primary' size='lg' className='mb-3' disabled onClick={this.registerShipper}>registering user...</Button>
      : <Button variant='primary' size='lg' className='mb-3' onClick={this.registerShipper}>Register</Button>
    let cardContent = this.state.registered 
      ? (
          this.state.registrationError
            ? (
              <div className='border border-info container card p-3'>
                <StdInput type='email' label='Email' placeholder='Enter Email' value={this.state.user.email} change={(event) => this.inputChangedHandler(event,'email')}/>
                <StdInput type='password' label='Password' placeholder='Enter Password' value={this.state.user.password} change={(event) => this.inputChangedHandler(event,'password')}/>
                <StdInput type='password' label='Confirm Password' placeholder='Re-enter Password' value={this.state.user.cfmPassword} change={(event) => this.inputChangedHandler(event,'cfmPassword')}/>
                <Alert className='w-100' variant='danger'>Unable to register user.<br/>{this.state.resMessage.map(row=>(<p className='mb-0'>{row}</p>))}</Alert>
                {submitButton}
              </div>
            )
            : (
              <div className=''>
                <Alert variant='success'>A registration email has been sent to {this.state.user.email}.<br/>If you would like to work with our API now, your secret key for testing is : <p className='font-weight-bold text-primary'>{localStorage.getItem('secretKey')}</p></Alert>
              </div>
            )
        )
      : (
        <div className='border border-info container card p-3'>
            <StdInput type='email' label='Email' placeholder='Enter Email' value={this.state.user.email} change={(event) => this.inputChangedHandler(event,'email')}/>
            <StdInput type='password' label='Password' placeholder='Enter Password' value={this.state.user.password} change={(event) => this.inputChangedHandler(event,'password')}/>
            <StdInput type='password' label='Confirm Password' placeholder='Re-enter Password' value={this.state.user.cfmPassword} change={(event) => this.inputChangedHandler(event,'cfmPassword')}/>
            {submitButton}
        </div>
      )
    return (
      <article className="col-lg-10 col-xxl-11 doc-content--center">
        <h1>API Integration</h1>
        <h5>
          Apply for testing credentials and start integrating with Janio's API.
        </h5>
        {cardContent}
      </article>
    );
  }
}

export default Register;