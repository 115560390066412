import React, { Component } from 'react';
import {Button, Collapse} from 'react-bootstrap';

import { ReactComponent as Chevron } from "../../../assests/images/chevron.svg";

import "./collapse.css";

class Collapsible extends Component {
  state = {
    open: false
  }

  toggleContent = () => {
    this.setState(prevState =>{
      return {open: !prevState.open}
    })
  }

  render() {
    const isExpanded = this.state.open ? "is-expanded" : "";

    return (
      <div className={`my-2 collapse-container rounded ${isExpanded}`}>
        <Button
          block
          variant="outline-dark"
          className="pt-2 border-0 text-left collapse__trigger"
          onClick={this.toggleContent}
        >
          {this.props.title}
          <span className="collapse__chevron">
            <Chevron />
          </span>
        </Button>
        <Collapse in={this.state.open} className="mx-3 collapse__content">
          {this.props.children}
        </Collapse>
      </div>
    );
  }
}

export default Collapsible;