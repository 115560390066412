import React from "react";
import { Navbar, Nav } from "react-bootstrap";

import logo from "../assests/images/janio-no-tag-white.png";

const Header = () => {
  let parts = window.location.pathname.split("/");
  const lastSegment = parts.pop() || parts.pop();

  return (
    <header className="row">
      <Navbar className="navbar--header">
        <Navbar.Brand>
          <a href="https://janio.asia/">
            <img src={logo} className="header-logo" alt="Janio logo" />
          </a>
        </Navbar.Brand>
        <Nav className="nav flex-row">
          <Nav.Link href="/" active={lastSegment === ""}>
            Register
          </Nav.Link>
          <Nav.Link href="/faq" active={lastSegment === "faq"}>
            FAQ
          </Nav.Link>
        </Nav>
      </Navbar>
    </header>
  );
};

export default Header;
