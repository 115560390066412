import React from 'react';
import {Collapse, Button} from 'react-bootstrap';

import { ReactComponent as Chevron } from "../../../assests/images/chevron.svg";

import './collapse.css';

const FaqCollapse = (props) => {
  const isExpanded = props.open ? "is-expanded" : "";
  
  let content = (
    <div id={props.id} className={isExpanded}>
      <Button
        variant="light"
        className="card p-2 border-0 w-100 text-left"
        onClick={props.click}
      >
        {props.title}
        <span className="collapse__chevron">
          <Chevron />
        </span>
      </Button>
      <Collapse in={props.open}>
        <div className="text-left text-secondary">
          <p className="p-2 mb-0">{props.text}</p>
        </div>
      </Collapse>
    </div>
  );
  return (
    <div className="border-dark border border-bottom-0 border-right-0 border-left-0">
      {content}
    </div>
  );
};

export default FaqCollapse;