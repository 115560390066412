import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Table } from 'react-bootstrap';
import axios from 'axios'

import {PageLayout, RequestExample, ResponseExample, TestResponse, RequestInputs} from '../components/pageSections';
import RequestParams from '../components/UI/tables/requestParams';
import {cancelParams} from '../utils/info';
import TerminalText from '../components/terminalText/terminal';
import Input from '../components/UI/input/stdInput';
import SubmitButton from '../components/UI/buttons/submitButton';
import {cutoffInfo} from '../utils/data';
import {ROOT_URL} from '../utils/urls';

const renderCancellationConditions = () => (
  <div className='text-left pl-2 mt-0 pt-0'>
    <h5>Conditions to Cancel Orders</h5>
    <p className='mr-2'>
      Orders may only be cancelled pre-pickup/pre-dropoff, where an order's status is still at <code >ORDER_INFO_RECEIVED</code>. 
      In addition, for orders being picked up, cancellation can only occur before the local cut-off time. 
      Beyond these points, the cost to fulfil the order would have been incurred and hence are non-cancellable.
    </p>
    <p>
      For orders that were not picked up, they may be cancelled on subsequent days from 12am to the applicable local cut-off time. 
      If they are not cancelled, it will be assumed that they are to be picked up.
    </p>
    <p>
      For all other cancellations, please contact your account manager or support to effect the cancellation.
    </p>
    <Table>
      <thead>
        <tr>
          <th>Country</th><th>Cutoff Timing</th>
        </tr>
      </thead>
      <tbody>
        { cutoffInfo.map(info => (
        <tr key={info.country}>
          <td>{info.country}</td><td>{info.cutoff}</td>
        </tr>
        ))}
      </tbody>
    </Table>
  </div>
)

const Cancel = (props) => {
  const didMount = useRef(false)
  const [trackingNos, setTrackingNos] = useState('')
  const [secretKey, setSecretKey] = useState(localStorage.getItem('secretKey') ? localStorage.getItem('secretKey') : '')
  const [loading, setLoading] = useState(false)
  const [clicked, setClicked] = useState(false)
  const [resp, setResponse] = useState(null)
  const [showResponse, setShowResponse] = useState(false)
  const responseExamples = [
    {status: 'Success', badgeVariant: 'success', statusCode: 200, terminalText: 'cancelSuccess'},
    {status: 'Failure', badgeVariant: 'danger', statusCode: 400, terminalText: 'cancelError'}
  ]
  let payload = {
    secret_key: secretKey,
    tracking_nos: trackingNos.replace(' ', '').split(',')
  }
  const method = 'POST'
  const endPt = `${ROOT_URL}/order/cancel-orders/`
  const element = document.getElementById('responseContent')
  if (element) {
    element.scrollIntoView({block:'center', behavior:'smooth'})
  }

  useEffect(() => {
    const cancel = async () => {
      try {
        localStorage.setItem('secretKey', secretKey)
        const response = await axios.post(endPt,
        payload, { headers: { 'Content-Type': 'application/json'} }
        )
        setResponse({data: response.data, hasError: false, statusCode: response.status})
        setShowResponse(true)
      }
      catch(e) {
        setResponse({data: e.response.data, hasError: true, statusCode: e.response.status})
        setShowResponse(true)
      }
      setLoading(false)
    }
    // only use the effect on update not on mount
    if (didMount.current) {
      setLoading(true)
      cancel()
    }
    else {
      didMount.current = true
    }
  }, [clicked])

  let inputs = (
    <RequestInputs title='Cancel Orders API Testing'>
      <div className='border rounded border-info px-3 pt-2 mb-2'>
        <h5>Cancel Orders Request Params</h5>
        <Input label='Secret Key' type='text' placeholder='Unique key assigned by Janio' change={(event) => {setSecretKey(event.target.value); setShowResponse(false)}} value={secretKey}/>
        <Input label='Tracking Numbers' type='text' placeholder='e.g. trackingnumber1,trackingnumber2' 
                extra='If entering multiple tracking numbers, separate them with `,`' change={(event) => {setTrackingNos(event.target.value); setShowResponse(false)}} value={trackingNos}/>
      </div>
      <SubmitButton loading={loading} variant='success' onClick={() => {setClicked(!clicked)}} submitText='Test Cancel Order' loadingText='cancelling order...'/>
    </RequestInputs>
  )

  let content = (
    <>
      <RequestExample title='Cancel Orders' method={method} badgeVariant='success' uri='/order/cancel-orders/'>
        <TerminalText text='cancelEg'/>
        <RequestParams params={cancelParams}/>
        {renderCancellationConditions()}
      </RequestExample>
      <ResponseExample examples={responseExamples}/>
      {showResponse && <TestResponse statusCode={resp.statusCode} req={payload} resp={resp.data} variant={resp.hasError ? 'danger' : 'success'} endPoint={`${method} ${endPt}`}/>}
    </>
  )

  return (
    <PageLayout left={content} right={inputs}/>
  )
}

export default Cancel