import React from 'react';
import { Badge } from 'react-bootstrap';
import TerminalText from '../terminalText/terminal';

const TestResponse = (props) => {

  return (
  <div id='responseContent' className='request-body'>
    <h5 className="font-weight-bold text-left">Test Response <Badge variant={props.variant}>{props.statusCode}</Badge></h5>
    <TerminalText res={props.resp} req={props.req} url={props.endPoint}/>
  </div>
  )
}

export default TestResponse