import React, { Component } from 'react';
import {Form, InputGroup, FormControl} from 'react-bootstrap';

import {createQnA} from '../utils/info';
import FaqCollapse from '../components/UI/collapse/faqCollapse';

class Faq extends Component {

  state = {
    params: createQnA,
    filter: '',
    hash: ''
  }

  componentDidMount() {
    window.addEventListener('hashchange', this.scrollOpenFaq);
    let hash = window.location.hash.substr(1)
    if (hash){
      setTimeout(()=>(this.scrollOpenFaq()),300) 
    }
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.scrollOpenFaq);
  }

  scrollOpenFaq = () => {
    let id = window.location.hash.substr(1)
    const element = document.getElementById(id);
    if (element) {
      console.log('called: ', id)
      element.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center'
    })
    this.setState({hash:id})
    }
  }

  toggleFaq = ident => {
    if (ident === this.state.hash){
      this.props.history.push(`/faq`)
      this.setState({hash:''})
    }else{
      this.props.history.push(`/faq#${ident}`)
      this.setState({hash:ident})
  }
  }

  filterFaq = () => {
    if (this.state.filter === '') {
      this.setState({params: createQnA})
    };
    const filterString = this.state.filter.replace(' ','_')
    const updatedList = createQnA.filter(params => (
      params.param.startsWith(filterString)
    ))
    this.setState({params: updatedList})
  };

  inputChangedHandler = (event) => {
    this.setState({filter: event.target.value},this.filterFaq)
  };

  render() {
    return (
      <article className="col-lg-10 col-xxl-11 doc-content--center">
        <div className="border border-dark rounded my-3 bg-white py-3 px-2">
          <h1>Frequently Asked Questions</h1>
          <h5>Answers to queries regarding our API</h5>
          <div>
            <Form.Label className="float-left mb-0">
              <h5>Request Parameter</h5>
            </Form.Label>
            <InputGroup className="mb-3">
              <FormControl
                className="border-dark"
                placeholder="Search for questions by request/query parameters, e.g. `secret_key` or `payment_type`"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                value={this.state.filter}
                onChange={this.inputChangedHandler}
              />
            </InputGroup>
          </div>
        </div>
        {this.state.params.map((params) => (
          <div
            key={params.param}
            id={params.param + "-section"}
            className="border border-dark rounded mt-4 bg-white"
          >
            <h5 className="text-primary my-2 px-2">{params.param}</h5>
            {params.questions.map((questions) => {
              let open = questions.ident === this.state.hash;
              return (
                <FaqCollapse
                  key={questions.question}
                  id={questions.ident}
                  open={open}
                  title={questions.question}
                  text={questions.ans}
                  click={() => this.toggleFaq(questions.ident)}
                />
              );
            })}
          </div>
        ))}
      </article>
    );
  }
}

export default Faq;