const texts = {
  'createEg': `{
    "secret_key": "string",
    "blocking": false,
    "orders": [
      {
        "service_id": 1,
        "tracking_no": "TRACKINGNUMBER1",
        "shipper_order_id": "ORDER123",
        "order_length": 12,
        "order_width": 12,
        "order_height": 12,
        "order_weight": 12,
        "payment_type": "cod",
        "cod_amt_to_collect": 40.5,
        "incoterm": "DDP",
        "identification_document_name": "LOCALTAXID",
        "consignee_identifcation_number": "S1234567G",
        "consignee_name": "Susan",
        "consignee_number": "+6291234567891",
        "consignee_country": "Indonesia",
        "consignee_address": "Pos 5 security komp perumahan PT INALUM tanjung gading., Jln berangin.",
        "consignee_postal": "12420",
        "consignee_state": "Daerah Khusus Ibukota Jakarta",
        "consignee_city": "Jakarta Selatan",
        "consignee_province": "Cilandak",
        "consignee_email": "susan123@email.com",
        "pickup_contact_name": "Jackson",
        "pickup_contact_number": "+6591234567",
        "pickup_country": "Singapore",
        "pickup_address": "Jurong West Ave 1",
        "pickup_postal": "640534",
        "pickup_state": "Singapore State",
        "pickup_city": null,
        "pickup_province": null,
        "items": [
          {
            "item_desc": "Blue Male T-Shirt",
            "item_category": "Fashion Apparel",
            "item_product_id": "PROD123",
            "item_sku": "ITEMSKU123",
            "item_quantity": 3,
            "item_price_value": 23.55,
            "item_price_currency": "IDR"
          }
        ]
      }
    ]
}`,
  'createSuccess': `// Tracking numbers in "tracking_nos" array are ordered in the same order of tracking numbers supplied

{
    "upload_batch_no": "ABC123456",
    "tracking_nos": [
      "TRACKINGNUMBER1",
      "TRACKINGNUMBER2",
      "TRACKINGNUMBER3"
    ]
}`,
  'createBlockingSuccess': `// Orders in "orders" array are ordered in the same order of orders supplied.
// "order_label_url" is the URL linking to the label of each order.

{
    "upload_batch_no": "ABC123456",
    "orders": [
      {
        "tracking_no": "TRACKINGNUMBER1",
        "order_label_url": "https://order-label-url.com/TRACKINGNUMBER1/"
      },
      {
        "tracking_no": "TRACKINGNUMBER2",
        "order_label_url": "https://order-label-url.com/TRACKINGNUMBER2/"
      },
      {
        "tracking_no": "TRACKINGNUMBER3",
        "order_label_url": "https://order-label-url.com/TRACKINGNUMBER3/"
      }
    ]
}`,
  'createBlockingAccepted': `// Orders in "orders" array are ordered in the same order of orders supplied.
// This case occurs when labels for one or more of the orders cannot be generated due to errors in submission to external partners.
// For orders whose labels are not generated, "order_label_url" is null and "message" is the error message.
// For these orders, you can try to obtain the order label URL some time again in the future using the view orders API.
// If order label URL remains to be unavailable, please contact clientsupport@janio.asia for assistance.

{
    "upload_batch_no": "ABC123456",
    "orders": [
      {
        "tracking_no": "TRACKINGNUMBER1",
        "order_label_url": "https://order-label-url.com/TRACKINGNUMBER1/"
      },
      {
        "tracking_no": "TRACKINGNUMBER2",
        "order_label_url": "https://order-label-url.com/TRACKINGNUMBER2/"
      },
      {
        "tracking_no": "TRACKINGNUMBER3",
        "order_label_url": null,
        "message": "We are unable to generate label for this order. Please contact our customer support at clientsupport@janio.asia for further assistance on label creation. Apologies for the inconvenience caused.'
      }
    ]
}`,
  'createError':`// Errors are sorted by the index of order submission, for e.g., the 2nd order submitted will always be index 1.

{
    "orders": [
      {
        "fieldname_as_key": [
          "Enter a valid email address."
        ]
      }
    ]
}`,

  'createTrackingError':`// Returns when tracking number is already in use
  
  {
    "orders": [
        {
            "tracking_no": [
                "The tracking number keyed in has already been used."
            ]
        }
    ]
  }`,

  'createOrderError':`// Returns when order value is incorrect
  {
    "orders": [
        {
            "order_width": [
                "A valid number is required."
            ]
        }
    ]
  }`,

  'createItemError':`// Returns when item value is incorrect
  {
    "orders": [
        {
            "items": [
                {
                    "item_quantity": [
                        "A valid integer is required."
                    ]
                }
            ]
        }
    ]
  }`,
'viewEg': `// Query parameters will be appended to the end of the endpoint as shown below

GET

https://api.int.janio.asia/api/order/order/?secret_key=SECRETKEY&with_items=true`,
'viewSuccess':`{
  "order_id": 12,
  "service_id": 1,
  "tracking_no": "TRACKINGNUMBER1",
  "agent_id": 4,
  "agent_application_id": 23,
  "hawb_no": null,
  "shipper_sub_account_id": "shipper ABC",
  "shipper_order_id": "ORDERNUM123",
  "store_id": null,
  "consignee_name": "Susan",
  "consignee_number": "+6591234567",
  "consignee_country": "Singapore",
  "consignee_address": "Jurong West Ave 1",
  "consignee_postal": 640534,
  "consignee_state": "Singapore State",
  "consignee_city": null,
  "consignee_province": null,
  "consignee_email": "susan123@email.com",
  "order_length": 0,
  "order_width": 0,
  "order_height": 0,
  "order_weight": 0,
  "pickup_contact_name": "Jackson",
  "pickup_contact_number": "+6291234567891",
  "pickup_country": "Indonesia",
  "pickup_address": "Pos 5 security komp perumahan PT INALUM tanjung gading., Jln berangin.",
  "pickup_postal": "12420",
  "pickup_state": "Daerah Khusus Ibukota Jakarta",
  "pickup_city": "Jakarta Selatan",
  "pickup_province": "Cilandak",
  "payment_type": "cod",
  "delivery_note": "string",
  "cod_amt_to_collect": 40.5,
  "order_label_url": "string",
  "status_code": null,
  "tracker_status_code": "SUCCESS",
  "tracker_main_text": "Delivery successfuly completed by Courier.",
  "upload_batch_no": "string",
  "created_on": "2018-07-02T06:25:41.338681Z",
  "updated_on": "2018-07-02T06:25:41.338726Z"
}`,
  'trackEg':`{
  "get_related_updates": true,
  "flatten_data": true,
  "tracking_nos": [
    "TRACKINGNUMBER1",
    "TRACKINGNUMBER2",
    "TRACKINGNUMBER3"
  ]
}`,
  'trackSuccess': `[
    {
        "update_id": 7674,
        "tracking_no": "TRACKINGNUMBER1",
        "main_text": "Order details received by courier.",
        "detail_text": null,
        "address": null,
        "status": "ORDER_INFO_RECEIVED",
        "original_country": null,
        "destination_country": null,
        "updated_on": "2019-02-28T05:07:22.066197Z",
        "tracker_application_id": null,
        "created_on": "2019-02-28T05:07:23.789097Z"
    }
]`,
  'trackError': `{
    "tracking_nos": [
        {
            "tracking_no": [
                "This field may not be blank."
            ]
        }
    ]
}`,
'trackv2Success': `{
  'TRACKINGNUMBER1': [
    {
      'tracking_no': 'TRACKINGNUMBER1',
      'status': 'DELIVERY_IN_PROGRESS',
      'updated_on': '2020-12-27T09:53:43.355455Z'
      'description': 'The parcel is on its way to the recipient. This may take several days depending on the country/region.',
      'country': null,
      'address': null,
    },
    {
      'tracking_no': 'TRACKINGNUMBER1',
      'status': 'IN_TRANSIT_WITHIN_DESTINATION_COUNTRY',
      'updated_on': '2020-12-17T04:53:43.355418Z'
      'description': 'The parcel has arrived at a warehouse and is awaiting processing.',
      'country': 'Singapore',
      'address': 'Changi Warehouse 123',
    },
  ],
  'TRACKINGNUMBER2': [
    {
      'tracking_no': 'TRACKINGNUMBER2',
      'status': 'IN_TRANSIT_WITHIN_ORIGIN_COUNTRY',
      'updated_on': '2020-12-16T23:53:43.355439Z'
      'description': 'The parcel is about to depart a warehouse for the next facility.',
      'country': 'Indonesia',
      'address': null,
    }
  ]
}`,
'trackv2SuccessFlat': `[
  {
    'tracking_no': 'TRACKINGNUMBER1',
    'status': 'DELIVERY_IN_PROGRESS',
    'updated_on': '2020-12-27T09:53:43.355455Z'
    'description': 'The parcel is on its way to the recipient. This may take several days depending on the country/region.',
    'country': null,
    'address': null,
  },
  {
    'tracking_no': 'TRACKINGNUMBER1',
    'status': 'IN_TRANSIT_WITHIN_DESTINATION_COUNTRY',
    'updated_on': '2020-12-17T04:53:43.355418Z'
    'description': 'The parcel has arrived at a warehouse and is awaiting processing.',
    'country': 'Singapore',
    'address': 'Changi Warehouse 123',
  },
  {
    'tracking_no': 'TRACKINGNUMBER2',
    'status': 'IN_TRANSIT_WITHIN_ORIGIN_COUNTRY',
    'updated_on': '2020-12-16T23:53:43.355439Z'
    'description': 'The parcel is about to depart a warehouse for the next facility.',
    'country': 'Indonesia',
    'address': null,
  }
]`,
'pricingEg': `{
  "secret_key": "OOhuGZ7OKUCiLZH56Z8L5OqH1mbhdlkR",
  "service_id": 1,
  "order_length": 2,
  "order_width": 2,
  "order_height": 2,
  "order_weight": 2,
  "pickup_country": "Singapore",
  "pickup_postal": "",
  "consignee_country": "Indonesia",
  "consignee_postal": "",
  "created_on": ""
}`,
'pricingSuccess': `
    4.00`,
'pricingError': `{
  "created_on": [
      "Datetime has wrong format. Use one of these formats instead: YYYY-MM-DDThh:mm[:ss[.uuuuuu]][+HH:MM|-HH:MM|Z]."
  ]
}`,
'label':`Headers:
{
  "authorization": "Basic amFuaW86MTIz", ← Basic Access Auth with username ‘Janio’ and password ‘123’
  "content-type": "application/json",
}
Payload:
{
  "tracking_no": "TRACKINGNUM123",
  "order_label_url": "https://www.print.com/document",
  "label_base64": "JVBERi0xLj...MNCiXi0YNCg=="
}`,
'cancelEg': `{
  'secret_key': "secretkey123",
  "tracking_nos": [
    "tracking123",
    "tracking456"
  ]
}`,
'cancelSuccess': `{
  "detail": "Order: 'tracking123', 'tracking456' has been cancelled."
}`,
'cancelError': `{
  "tracking123": "Orders have passed the cutoff time for cancellation.",
  "tracking456": "Orders cannot be cancelled from this pickup country"
}`,
'returnEg': `{
  "secret_key": 1,
  "orders": [
    {
      "service_type": "PICKUP",
      "shipper_order_id": "text",
      "consignee_name": "text",
      "consignee_address": "text",
      "consignee_postal": "123455",
      "consignee_country": "Singapore",
      "consignee_city": "Singapore",
      "consignee_state": "Singapore",
      "consignee_number": "+6598765432",
      "consignee_email": "text@email.com",
      "order_length": 1.1,
      "order_width": 1.1,
      "order_height": 1.1,
      "order_weight": 1.1,
      "pickup_contact_name": "Max",
      "pickup_contact_email": "bob@email.bob",
      "pickup_contact_number": "61234567",
      "pickup_country": "Singapore",
      "pickup_state": "Singapore",
      "pickup_city": "Singapore",
      "pickup_postal": "624789",
      "pickup_address": "text",
      "items": [
        {
          "item_desc": "text",
          "item_quantity": 1,
          "item_product_id": "text",
          "item_sku": "text",
          "item_category": "Fashion Accessories",
          "item_price_value": 1.1,
          "item_price_currency": "USD"
        }
      ]
    }
  ]
}
`,
'returnSuccess': `// Returns array of tracking_no objects
// Contains the "return_tracking_no" as well as the optional "forward_tracking_no" if one was supplied
// Tracking numbers in "tracking_nos" array are ordered in the same order of tracking numbers supplied

{
  "upload_batch_no": "POG201112123238",
  "tracking_nos": [
      {"return_tracking_no": "return123"},
      {"return_tracking_no": "return456", "forward_tracking_no": "forward456"},
      {"return_tracking_no": "return789", "forward_tracking_no": "forward789"},
  ]
}`,
'returnError':`// Errors are sorted by the index of order submission, for e.g., the 2nd order submitted will always be index 1.

{
    "orders": [
      {
        "fieldname_as_key": [
          "Enter a valid email address."
        ]
      }
    ]
}`,
'createMPSeg': `{
  "secret_key": "String",
  "orders": [
      {
          "mps_group_id": "1",
          "mps_parent_tracking_no": "MYOWNTRACKINGPARENTSO29",
          "shipper_order_id": "s1",
          "special_instruction(Max": "SI1",
          "package_order_id": "1",
          "child_tracking_no": "",
          "item_desc": "item_1_of_package1",
          "item_quantity": "2",
          "item_product_id": "XYZ123",
          "item_sku": "",
          "item_category": "Electronics",
          "item_price_value": "20.25",
          "item_price_currency": "SGD",
          "pickup_country": "Singapore",
          "consignee_name": "Jason",
          "consignee_number": "91234567",
          "consignee_address": "Blk 550 Jurong East Ave 1",
          "consignee_postal": "640550",
          "consignee_country": "Singapore",
          "consignee_state": "Jurong",
          "consignee_city": "",
          "consignee_province": "",
          "consignee_email": "jason@email.com",
          "order_length": "1.2",
          "order_width": "5",
          "order_height": "2.43",
          "order_weight": "1.2",
          "payment_type": "prepaid",
          "service_level": "",
          "identification_document_name": "",
          "consignee_identifcation_number": "",
          "service_id": 10,
          "pickup_contact_name": "dddsss",
          "pickup_contact_number": "+6512345678",
          "pickup_state": "Central Singapore",
          "pickup_city": "Singapore",
          "pickup_province": "Singapore",
          "pickup_postal": "366788",
          "pickup_address": "singapore 123 fiwn",
          "pickup_date": "2022-02-01",
          "pickup_notes": "Wake me up so that you can pick me up2dsa555",
          "packages": [
              {
                  "child_tracking_no": "",
                  "package_no": "1",
                  "length": "1.2",
                  "weight": "1.2",
                  "height": "2.43",
                  "width": "5",
                  "items": [
                      {
                          "item_desc": "item_1_of_package1",
                          "item_category": "Electronics",
                          "item_price_currency": "SGD",
                          "item_product_id": "XYZ123",
                          "item_quantity": "2",
                          "item_price_value": "20.25",
                          "item_sku": "",
                          "shipper_order_id": "s1",
                          "special_instruction": ""
                      }
                  ]
              },
              {
                  "child_tracking_no": "",
                  "package_no": "5",
                  "length": "1.2",
                  "weight": "1.2",
                  "height": "2.43",
                  "width": "5",
                  "items": [
                      {
                          "item_desc": "item_2_of_package1",
                          "item_category": "Electronics",
                          "item_price_currency": "SGD",
                          "item_product_id": "XYZ123",
                          "item_quantity": "2",
                          "item_price_value": "20.25",
                          "item_sku": "",
                          "shipper_order_id": "s5",
                          "special_instruction": ""
                      }
                  ]
              },
              {
                  "child_tracking_no": "",
                  "package_no": "2",
                  "length": "1.2",
                  "weight": "1.2",
                  "height": "2.43",
                  "width": "5",
                  "items": [
                      {
                          "item_desc": "item_1_of_package2",
                          "item_category": "Electronics",
                          "item_price_currency": "SGD",
                          "item_product_id": "XYZ123",
                          "item_quantity": "2",
                          "item_price_value": "20.25",
                          "item_sku": "",
                          "shipper_order_id": "s2",
                          "special_instruction": ""
                      }
                  ]
              },
              {
                  "child_tracking_no": "",
                  "package_no": "3",
                  "length": "1.2",
                  "weight": "1.2",
                  "height": "2.43",
                  "width": "5",
                  "items": [
                      {
                          "item_desc": "item_1_of_package3",
                          "item_category": "Electronics",
                          "item_price_currency": "SGD",
                          "item_product_id": "XYZ123",
                          "item_quantity": "2",
                          "item_price_value": "20.25",
                          "item_sku": "",
                          "shipper_order_id": "s7",
                          "special_instruction": ""
                      }
                  ]
              },
              {
                  "child_tracking_no": "",
                  "package_no": "4",
                  "length": "1.2",
                  "weight": "1.2",
                  "height": "2.43",
                  "width": "5",
                  "items": [
                      {
                          "item_desc": "Item_2_of_package3",
                          "item_category": "Electronics",
                          "item_price_currency": "SGD",
                          "item_product_id": "XYZ123",
                          "item_quantity": "2",
                          "item_price_value": "20.25",
                          "item_sku": "",
                          "shipper_order_id": "s3",
                          "special_instruction": ""
                      }
                  ]
              }
          ],
          "tracking_no": "MYOWNTRACKINGPARENTSO29"
      }
  ]
}`,
'createMPSSuccess': `// Returns the count of orders recieved

{
  "count":1
}`,
'createServerErrorError': `// Returns sometimes when payload is not proper

Server Error (500)`,
'createMPSBadRequestError': `// Returns when request has missing keys

Bad Request`,
'createMPSTrakingNumberError': `// Returns when Requested tracking number is already in use
{
  "tracking_no": [
      "The tracking number keyed in has already been used."
  ]
}`,

'consigneeNumberError': `// Returns when country code doesn't match
{
  "consignee_number": [
      "Country code in number (+62) does not match country code of country (+60)."
  ]
}`,

'itemValueError': `// Returns when any required field is left blank
{
  "packages": {
      "items": [
          {
              "item_price_value": [
                  "A valid number is required."
              ]
          }
      ]
  }
}`

}

export default texts;
