import React, { useEffect } from "react";
import Prism from "prismjs";
import "prismjs/components/prism-json";
import "prismjs/themes/prism-tomorrow.css";

import "./terminaltexts";
import texts from "./terminaltexts";

const stringPretty = (str) => {
  return JSON.stringify(str, null, 4);
};

const terminalText = (props) => {
  let text = "";
  useEffect(() => {
    Prism.highlightAll();
  });
  
  if (props.res) {
    const res = stringPretty(props.res);
    const req = stringPretty(props.req);
    text = `############
  ENDPOINT 
############

    ${props.url}


############
  REQUEST
############

${req}


############
  RESPONSE
############

${res}
    `;
  } else {
    text = texts[props.text];
  }
  return (
    <pre className="terminal-container">
      <code className="language-json">{text}</code>
    </pre>
  );
};

export default terminalText;
