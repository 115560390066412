export const serviceMappings = [
  '1: Singapore-Indonesia',
  '6: China-Indonesia',
  '5: China-Singapore',
  '7: China-Thailand',
  '11: Hong Kong-Indonesia',
  '37: Hong Kong-Malaysia',
  '33: Hong Kong-Philippines',
  '13: Hong Kong-Singapore',
  '12: Hong Kong-Thailand',
  '41: Indonesia-China',
  '41: Indonesia-Hong Kong',
  '41: Indonesia-Taiwan',
  '41: Indonesia-Brunei',
  '41: Indonesia-Philippines',
  '41: Indonesia-Malaysia',
  '3: Indonesia-Indonesia',
  '47: Indonesia-Philippines',
  '54: Indonesia-Philippines',
  '20: Indonesia-Singapore',
  '48: Indonesia-Singapore',
  '22: Indonesia-SouthKorea',
  '22: Indonesia-Japan',
  '22: Indonesia-Vietnam',
  '4: Malaysia-Indonesia',
  '40: Malaysia-Malaysia',
  '36: Philippines-Philippines',
  '2: Singapore-China',
  '2: Singapore-Taiwan',
  '2: Singapore-SouthKorea',
  '2: Singapore-Brunei',
  '2: Singapore-Vietnam',
  '2: Singapore-HongKong',
  '2: Singapore-Japan',
  '26: Singapore-Malaysia',
  '10: Singapore-Singapore',
  '17: Singapore-Thailand',
  '34: Thailand-Indonesia',
  '35: Thailand-Singapore'
]

export const serviceLevels = [
  '',
  'Next Day Delivery',
  'Regular'
]

export const trackingStatus = [
  'ORDER_INFO_RECEIVED',
  'ORDER_PICKED_UP',
  'INTRA_ORIGIN_TRANSFER',
  'ORDER_RECEIVED_AT_LOCAL_SORTING_CENTER',
  'ORDER_RECEIVED_BY_AIRLINE',
  'PENDING_CUSTOMS_CLEARANCE',
  'ORDER_RECEIVED_AT_DESTINATION_WAREHOUSE',
  'INTRA_DESTINATION_TRANSFER',
  'DELIVERY_IN_PROGRESS',
  'SUCCESS',
  'RETURNED_TO_CLIENT',
  'RETURNED_TO_DESTINATION_WAREHOUSE',
  'DESTROYED_AT_DESTINATION_WAREHOUSE',
  'CANCELLED_BY_CUSTOMER',
  'DELIVERY_FAILED'
]

export const publicStatusV2 = [
  'ORDER_CREATED',
  'PENDING_PICKUP',
  'FAILED_PICKUP',
  'READY_FOR_COLLECTION',
  'IN_TRANSIT_WITHIN_ORIGIN_COUNTRY',
  'CROSS_BORDER_TRANSIT',
  'IN_TRANSIT_WITHIN_DESTINATION_COUNTRY',
  'DESTINATION_COUNTRY_CUSTOMS',
  'DELIVERY_IN_PROGRESS',
  'DELIVERY_COMPLETED',
  'FAILED_DELIVERY',
  'RETURN_TO_SENDER',
  'EXCEPTION',
  'CANCELLED'
]

export const itemCategories = [
  'Fashion Apparel',
  'Printed Matters',
  'Lifestyle Products',
  'Electronics',
  'Others'
]

export const cutoffInfo = [
  {country: 'Philippines', cutoff: '12:00 PM'},
  {country: 'Singapore', cutoff: '11:00 AM'},
  {country: 'Malaysia', cutoff: '11:00 AM'},
  {country: 'Indonesia', cutoff: '10:00 AM'},
  {country: 'Thailand', cutoff: '10:00 AM'},
  {country: 'Taiwan', cutoff: '10:00 AM'},
  {country: 'India', cutoff: '08:00 AM'}
]