import React, { Component } from 'react';
import { Button, Badge, Table } from 'react-bootstrap';
import axios from 'axios';

import {ROOT_URL} from '../utils/urls';

import {PageLayout, RequestInputs} from '../components/pageSections';
import Input from '../components/UI/input/stdInput';
import TerminalText from '../components/terminalText/terminal';
import ServicesDropdown from '../components/UI/input/servicesDropdown';
import {viewDesc} from '../utils/info';

class View extends Component {

  state = {
    queryRes: '',
    queryUrl: '',
    loading: false,
    querySubmitted: false,
    submitButtonClicked: false,
    queryError: {error:false, code: 0},
    serviceString: '',
    query: {
      secret_key: localStorage.getItem('secretKey') ? localStorage.getItem('secretKey') : '',
      with_items: 'true',
      date_from: null,
      date_to: null,
      service_id: '',
      shipper_order_id: '',
      upload_batch_no: '',
      page: ''
    }
  }

  parseQueryParams = (obj) => {
    let qp = '?'
    for (const key in obj) {
      if (obj[key]){
        if (key ==='date_from' || key==='date_to'){
          qp+=(key+'='+obj[key].toISOString()+'&')
        }else{
        qp+=(key+'='+obj[key]+'&')}
      };
    };
    return (qp.substring(0, qp.length-1))
  };

  querySubmitHandler = () => {
    this.setState({loading: true})
    this.setState({querySubmitted: false})
    const baseUrl = ROOT_URL + '/order/order/'
    const query = this.parseQueryParams(this.state.query)
    this.setState({queryUrl: baseUrl+query})
    axios.get(baseUrl+query)
    .then(response => {
      console.log(response.data)
      localStorage.setItem('secretKey', this.state.query.secret_key)
      this.setState({queryRes: response.data})
      this.setState({queryError: {error: false, code: response.status}})
      this.setState({querySubmitted: true})
      this.setState({loading: false})
    }).catch(error => {
      this.setState({queryError: {error: true, code: error.response.status}})
      this.setState({queryRes: error.response.data})
      this.setState({querySubmitted: true})
      this.setState({loading: false})
    });
  this.setState({submitButtonClicked: true})
  };

  componentDidMount = () => {
    const datePickerWrapperList = document.querySelectorAll('.react-datepicker-wrapper');
    datePickerWrapperList.forEach((item) => {
      item.style.display = 'block';
    });

    const datePickerList = document.querySelectorAll('.react-datepicker__input-container');
    datePickerList.forEach((item) => {
      item.style.display = 'block';
    });
  }

  componentDidUpdate = () => {
    
    const element = document.getElementById("responseContent");
    if (element  && this.state.submitButtonClicked) {
      element.scrollIntoView({behavior: "smooth"});
      this.setState({submitButtonClicked: false})
    }
  }

  inputChangedHandler = (event, inputIdentifier) => {
    this.setState({querySubmitted: false})
    const updatedQueries = {
      ...this.state.query
    }
    updatedQueries[inputIdentifier] = event.target.value;
    this.setState({query: updatedQueries})
  };

  handleDateChanged = (date,inputIdentifier) => {
    this.setState({querySubmitted: false})
    
    const updatedQueries = {
      ...this.state.query
    }
    
    updatedQueries[inputIdentifier] = date;
    this.setState({query: updatedQueries})
  };

  onServiceSelected = (event) => {
    this.setState({querySubmitted: false})
    const serviceID = event.target.value.split(':')[0]
    const updatedQueries = {
      ...this.state.query
    }
    
    updatedQueries['service_id'] = parseInt(serviceID);
    this.setState({query: updatedQueries})
    this.setState({serviceString:event.target.value})
  }

  render() {
    let submitButton = this.state.loading
      ? <Button block variant='success' className='pt-3'  disabled onClick={this.querySubmitHandler}> <h5>fetching orders...</h5></Button>
      : <Button block variant='success' className='pt-3' onClick={this.querySubmitHandler}> <h5>Test View Orders</h5></Button>

    let responseStatus = this.state.queryError.error
      ? <Badge variant='danger'>{this.state.queryError.code}</Badge>
      : <Badge variant='success'>{this.state.queryError.code}</Badge>

    let responseContent = this.state.querySubmitted
    ? (
      <div id='responseContent' className='card py-2 my-4 border-dark' ref='responseContent'>
        <h4 className=''>Test Response {responseStatus}</h4>
        <TerminalText res={this.state.queryRes} req='The request body is empty' url={'GET '+this.state.queryUrl}/>
      </div>)
    : null;

    let inputs = (
      <RequestInputs title="View Orders API Testing">
        <div className="border rounded border-info px-3 pt-2 mb-2">
          <h5>View Orders Query Params</h5>
          <Input
            label="Secret Key"
            type="text"
            placeholder="Unique key assigned by Janio"
            change={(event) => this.inputChangedHandler(event, "secret_key")}
            value={this.state.query.secret_key}
          />
          <ServicesDropdown
            optional
            value={this.state.serviceString}
            change={(event) => this.onServiceSelected(event)}
          />
          <Input
            label="Date From"
            placeholder="Filter from earliest date"
            optional
            datetime
            change={(date) => this.handleDateChanged(date, "date_from")}
            selected={this.state.query.date_from}
          />
          <Input
            label="Date To"
            placeholder="Filter before latest date"
            optional
            datetime
            change={(date) => this.handleDateChanged(date, "date_to")}
            selected={this.state.query.date_to}
          />
          <Input
            label="With Items"
            select
            options={["true", "false"]}
            optional
            change={(event) => this.inputChangedHandler(event, "with_items")}
            value={this.state.query.with_items}
          />
          <Input
            label="Shipper Order Id"
            type="text"
            optional
            placeholder="Enter shipper order id"
            change={(event) =>
              this.inputChangedHandler(event, "shipper_order_id")
            }
            value={this.state.query.shipper_order_id}
          />
          <Input
            label="Upload Batch Number"
            type="text"
            optional
            placeholder="Enter upload batch number"
            change={(event) =>
              this.inputChangedHandler(event, "upload_batch_no")
            }
            value={this.state.query.upload_batch_no}
          />
          <Input
            label="Page"
            type="text"
            optional
            placeholder="Page of view orders, starting page is 1"
            change={(event) => this.inputChangedHandler(event, "page")}
            value={this.state.query.page}
          />
        </div>
        {submitButton}
      </RequestInputs>
    );
    
    let content = (
      <>
        <div className="request-body">
          <div className="d-flex">
            <h4 className="request-body__title">
              View Orders <Badge variant="info">GET</Badge>{" "}
            </h4>
            <h6 className="text-muted pt-2 ml-2">/order/order/</h6>
          </div>
          <h5 className="font-weight-bold text-left ml-2">Request Example</h5>
          <p className="font-weight-bold text-left ml-2 mb-0">
            Development Endpoint:{" "}
            <p className="text-primary d-inline font-weight-lighter">
              {" "}
              https://api.int.janio.asia/api/order/order/
            </p>
          </p>
          <p className="font-weight-bold text-left ml-2 mt-0">
            Production Endpoint:{" "}
            <p className="text-primary d-inline font-weight-lighter">
              {" "}
              https://api.janio.asia/api/order/order/
            </p>
          </p>
          <TerminalText text="viewEg" />
          <p className="font-weight-bold text-left ml-2 mt-4">
            Query Parameters
          </p>
          <Table>
            <tbody>
              {viewDesc.map((row) => (
                <tr key={row.title} className="d-flex">
                  <td className="col-md-4 text-left">
                    <p className="font-weight-bold my-0 d-inline">
                      {row.title}
                    </p>
                    {row.required && (
                      <p
                        className="text-danger d-inline"
                        style={{ fontSize: 14 }}
                      >
                        {" "}
                        * required
                      </p>
                    )}
                    <p className="text-primary my-1">{row.type}</p>
                  </td>
                  <td className="col-md-8 text-left">
                    <h6>{row.desc}</h6>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <div className="request-body">
          <h4 className="">Response Examples</h4>
          <p className="font-weight-bold text-left ml-2 mt-3">
            Success <Badge variant="success">200</Badge>{" "}
          </p>
          <TerminalText text="viewSuccess" />
          <p className="font-weight-bold text-left ml-2 mt-3">
            Failure <Badge variant="danger">400</Badge>{" "}
          </p>
          <TerminalText text="createError" />
        </div>

        {responseContent}
      </>
    );
    
    return (
      <PageLayout left={content} right={inputs}/>
    )
  }
}

export default View;