import React, { Component } from 'react';
import {Badge, Table, Tabs, Tab} from 'react-bootstrap';

import {webhookEndpt, urlLink, pdfLink} from '../utils/info';

class Labels extends Component {

  render() {

    return (
      <article className="col-lg-10 col-xxl-11 doc-content--center">
          <h1>Order Labels</h1>
          <div className='border border-info container card p-3 mt-2'>
            <p className="text-left font-weight-bolder">There are 3 ways to get the label from Janio:</p>
            <Table bordered>
              <thead className="bg-secondary">
                <tr className="text-white">
                  <th>Method Description</th>
                  <th>Suitable for...</th>
                  <th>Complexity</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-left">URL link that can be generated after calling Submit Order API</td>
                  <td className="text-left">Users that will print labels 1 by 1 Users that will print using a browser page</td>
                  <td className="text-left">Low</td>
                </tr>
                <tr>
                  <td className="text-left"><Badge variant="light">.pdf</Badge> link that can be obtained by querying the View Order API</td>
                  <td className="text-left">System/User that must print using a .pdf file System/Users that prints the labels in bulk (see notes below regarding bulk printing) </td>
                  <td className="text-left">Medium</td>
                </tr>
                <tr>
                  <td className="text-left">Webhook endpoint that will wait for a .pdf link </td>
                  <td className="text-left">System/User that must print using a .pdf file System/Users that prints the labels in bulk (see notes below regarding bulk printing)</td>
                  <td className="text-left">High</td>
                </tr>
              </tbody>
            </Table>
            <p className='text-left font-weight-bolder'>For more information regarding our labels, please vist our <a href='/faq#labels-section'>FAQ Page</a>.</p>
          </div>
          <div className='border border-info card container my-3 p-3'>
            <Tabs defaultActiveKey="url" id="links">
              <Tab className="text-left" eventKey="url" title="URL Link">
                {urlLink}
              </Tab>
              <Tab className="text-left" eventKey="pdf" title="Pdf link">
                {pdfLink}
              </Tab>
              <Tab className="text-left" eventKey="webhook" title="Webhook Endpoint">
                {webhookEndpt}
              </Tab>
            </Tabs>
          </div>
      </article>
    );
  }
}

export default Labels;