import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

function select(props) {
  const {
    label,
    placeholder,
    active,
    onChange,
    options,
    disabled,
    className
  } = props;

  return (
    <React.Fragment>
      {label && <h6 className="form-label">{label}</h6>}
      <Select
        onChange={onChange}
        showSearch
        value={active}
        placeholder={placeholder}
        className={"single-select " + className}
        optionFilterProp="children"
        disabled={disabled}
      >
        {options.map((option, index) => {
          return (
            <Option
            key={index}
            value={option}
            >
              {option}
            </Option>
          );
        })
        }
      </Select>
    </React.Fragment>
  )
}

export default select;