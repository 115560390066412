import React, { Component } from 'react';
import axios from 'axios';

import Input from '../components/UI/input/stdInput';

class Location extends Component {

  state = {
    countries: ['loading countries...'],
    filteredStates: ['Select a country to view its list of cities.'],
    filteredCities: ['Select a country to view its list of cities.'],
    cities: [],
    states: [],
    cityfilter: '',
    statefilter: ''
  }

  componentDidMount = () => {
    // fetch list of countries
    axios.get('https://api.janio.asia/api/location/countries/')
      .then(response => {
        console.log(response.data)
        let ctry = ['select a country'].concat(response.data.map(ctry => ctry.country_name).sort())
        this.setState({countries: ctry})
      }).catch(error => {
        console.log(error)
      });
  };

  filterCities = () => {
    if (this.state.cityfilter === '') {
      this.setState({filteredCities: this.state.cities})
    };
    const updatedList = this.state.cities.filter(params => (
      params.toLowerCase().includes(this.state.cityfilter)
    ))
    this.setState({filteredCities: updatedList})
  };

  filterStates = () => {
    if (this.state.statefilter === '') {
      this.setState({filteredStates: this.state.states})
    };
    const updatedList = this.state.states.filter(params => (
      params.toLowerCase().includes(this.state.statefilter)
    ))
    this.setState({filteredStates: updatedList})
  };

  inputChangedHandler = (event, inputIdentifier) => {
    
    console.log(event.target.value)
    // fetch list of cities
    if (inputIdentifier === 'country'){
    this.setState({filteredCities: ['loading cities...']})
    this.setState({filteredStates: ['loading states...']})
    axios.get('https://api.janio.asia/api/location/cities/?countries='+event.target.value)
      .then(response => {
        console.log(response.data)
        this.setState({cities: response.data.map(cty => cty.display_name).sort()})
        this.setState({filteredCities: response.data.map(cty => cty.display_name).sort()})
      }).catch(error => {
        console.log(error)
      });
    
      axios.get('https://api.janio.asia/api/location/states/?countries='+event.target.value)
      .then(response => {
        console.log(response.data)
        this.setState({states: response.data.map(st => st.state_name).sort()})
        this.setState({filteredStates: response.data.map(st => st.state_name).sort()})
      }).catch(error => {
        console.log(error)
      });
    }
      else if (inputIdentifier === 'city'){ //filter cities
        this.setState({cityfilter:event.target.value.toLowerCase()}, this.filterCities)
      }else{ //filter states
        this.setState({statefilter:event.target.value.toLowerCase()}, this.filterStates)
      }
  };
  
  render() {
    
    let cardContent =  (
      <div>
        <div className='rounded mb-3 border border-info container card p-3'>
          <Input noLabel select options={this.state.countries} change={(event) => this.inputChangedHandler(event,'country')} label='Countries'/>
        </div>
        <div className='rounded border border-info container card p-3 mb-3'>
          <Input 
            noLabel label='States' placeholder='Search for states' optional
            change={(event) => this.inputChangedHandler(event,'state')}/>
          <div style={{height:'300px', overflow:'auto'}} className='border border-secondary rounded'>
            {this.state.filteredStates.map(st=><p className='text-left m-2'>{st}</p>)}
          </div>
        </div>
        <div className='rounded border border-info container card p-3'>
          <Input 
            noLabel label='Cities' placeholder='Search for cities' optional
            change={(event) => this.inputChangedHandler(event,'city')}/>
          <div style={{height:'300px', overflow:'auto'}} className='border border-secondary rounded'>
            {this.state.filteredCities.map(cty=><p className='text-left m-2'>{cty}</p>)}
          </div>
        </div>
      </div>
      )
    return (
      <article className="col-lg-10 col-xxl-11 doc-content--center">
          <h1>List of Locations</h1>
          <h5>
            List of countries and cities within our database.
          </h5>
          {cardContent}
      </article>
      
    );
  }
}

export default Location;