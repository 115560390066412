export let ROOT_URL = 'https://api.int.janio.asia/api';

const url = window.location.href;
if (process.env.REACT_APP_IS_LOCAL) {
  ROOT_URL = 'http://apidocs.janio.local/api';
} else if (process.env.REACT_APP_IS_STAGING) {
  ROOT_URL = 'https://apidocs.staging.janio.asia/api';
} else {
  if (url.includes('localhost')) {
    ROOT_URL = 'https://apidocs.staging.janio.asia/api';
  }
}
