import React, { useState } from 'react';
import { Spin } from 'antd';
import { JANIO_MAIN_API_URL } from '../utils/janioMainApi';
import { SECRET_KEY_JANIO_API } from '../utils/secretKeyApi';
import Select from '../components/UI/select/select';
import AntDInput from '../components/UI/input-antd/input';
import useCountries from '../hooks/useCountries';
import useServiceDef from '../hooks/useServiceDef';
import axios from 'axios';
import * as _ from 'lodash';

export default function ServiceFinder() {
  const [pickup_country, setPickup_country] = useState(null);
  const [destination_country, setDestination_country] = useState(null);
  const [service_type, setService_type] = useState(null);
  const [service_id, setService_id] = useState(null);
  const [selected_dropoff_address, setSelected_dropoff_address] = useState(null);
  const [dropOff_data, setDropOff_data] = useState([]);
  const [dropoff_addresses, setDropoff_addresses] = useState([]);
  const [dropoff_id, setDropoff_id] = useState(null);

  const [serviceTypes, setServiceTypes] = useState([]);
  const [serviceTypeError, setServiceTypeError] = useState(null);
  const [serviceTypeLoading, setServiceTypeLoading] = useState(false);

  const countries = useCountries();
  const [serviceDefinitions] = useServiceDef(SECRET_KEY_JANIO_API);

  const serviceTypeClass = serviceTypeError ? 'input-error' : '';
  const dropoffAddressClass = service_type === 'Dropoff' ? '' : 'd-none';
  const serviceIdClass = service_type === 'Dropoff' ? 'mt-3' : '';

  return (
    <article className="col-lg-10 col-xxl-11 doc-content--center">
      <h1>Service Finder</h1>
      <h5>
        Find service ID between countries within our database.
      </h5>
      <div>
        <div className='rounded mb-3 border border-info container card p-3'>
          <div className="row pb-2">
            <div className="col-md-6 text-left">
              <Select
                className="w-100"
                label="Pickup Country"
                active={pickup_country}
                options={countries}
                placeholder="Pickup Country"
                onChange={onPickupCountryChange}
              />
            </div>
            <div className="col-md-6 text-left">
              <Select
                className="w-100"
                label="Destination Country"
                active={destination_country}
                options={countries}
                placeholder="Destination Country"
                onChange={onDestinationCountryChange}
              />
            </div>
          </div>
        </div>
        {destination_country && <div className='rounded border border-info container card p-3 mb-3'>
          <div className="row">
            {serviceTypes && serviceTypes.length === 0 && <div className="col-md-6">
              <p className="mb-0 text-left">No Service Available</p>
            </div>}
            {serviceTypes && serviceTypes.length > 0 && <div className="col-md-6 text-left">
              <Select
                className={"w-100 " + serviceTypeClass}
                label="Choose a Service"
                active={service_type}
                options={serviceTypes}
                placeholder="Choose a Service"
                onChange={onServiceTypeChange}
              />
              {serviceTypeError && <div className="error-message">
                {serviceTypeError}
              </div>}
            </div>}
            <div className={"col-md-6 text-left " + dropoffAddressClass}>
              <Select
                className="w-100"
                label="Choose Dropoff Address"
                active={selected_dropoff_address}
                options={dropoff_addresses}
                placeholder="Choose Dropoff Address"
                onChange={onDropOffAddressChange}
              />
            </div>
            {dropoff_id && service_type === 'Dropoff' && !serviceTypeLoading && <div className="col-md-6 mt-3 text-left">
              <AntDInput
                readOnly
                label="Drop Off Id"
                placeholder="Drop Off Id"
                value={dropoff_id}
              />
            </div>}
            {service_id && !serviceTypeLoading && <div className={"col-md-6 text-left " + serviceIdClass}>
              <AntDInput
                readOnly
                label="Service Id"
                placeholder="Service Id"
                value={service_id}
              />
            </div>}
            {serviceTypeLoading && <div className="col-md-6 text-left mt-3 pt-2">
              <Spin />
            </div>}
          </div>
        </div>}
      </div>
    </article>
  );

  async function onPickupCountryChange(value) {
    setPickup_country(value);

    if (destination_country) {
      setServiceTypeError(null);
      const serviceTypesToSave = await getServiceTypeOptions(value, destination_country);
      setServiceTypes(serviceTypesToSave);

      if (serviceTypesToSave.length === 0) {
        setService_type(null);
        setService_id(null);
        setSelected_dropoff_address(null);
        setDropoff_id(null);
        setDropoff_addresses([]);
        setDropOff_data([]);
      };

      if (serviceTypesToSave.length !== 0 && service_type === 'Pickup') {
        getServiceId(service_type, dropoff_id, value, destination_country);
      } else {
        let dropOffData = getDropOffAddresses(value, destination_country);
        let dropOffAddresses = _.map(dropOffData, 'dropoff_address');
        dropOffAddresses = _.filter(dropOffAddresses, x => x !== undefined);
        setDropoff_addresses(dropOffAddresses);
        setDropOff_data(dropOffData);
        setDropoff_id(null);
        setService_id(null);
        setSelected_dropoff_address(null);
      };
    };
  };

  async function onDestinationCountryChange(value) {
    setDestination_country(value);

    const serviceTypesToSave = await getServiceTypeOptions(pickup_country, value);
    setServiceTypes(serviceTypesToSave);

    setServiceTypeError(null);

    if (serviceTypesToSave.length === 0) {
      setService_type(null);
      setService_id(null);
      setSelected_dropoff_address(null);
      setDropoff_id(null);
      setDropoff_addresses([]);
      setDropOff_data([]);
    };

    if (pickup_country && service_type === 'Pickup' && serviceTypesToSave.length !== 0) {
      getServiceId(service_type, dropoff_id, pickup_country, value);
    } else {
      let dropOffData = getDropOffAddresses(pickup_country, value);
      let dropOffAddresses = _.map(dropOffData, 'dropoff_address');
      dropOffAddresses = _.filter(dropOffAddresses, x => x !== undefined);
      setDropoff_addresses(dropOffAddresses);
      setDropOff_data(dropOffData);
      setDropoff_id(null);
      setService_id(null);
      setSelected_dropoff_address(null);
    }
  };

  function onServiceTypeChange(value) {
    setService_type(value);
    setService_id(null);
    if (value === 'Pickup') {
      getServiceId(value, dropoff_id, pickup_country, destination_country);
    } else {
      let dropOffData = getDropOffAddresses(pickup_country, destination_country);
      let dropOffAddresses = _.map(dropOffData, 'dropoff_address');
      dropOffAddresses = _.filter(dropOffAddresses, x => x !== undefined);
      setDropoff_addresses(dropOffAddresses);
      setDropOff_data(dropOffData);
      if (selected_dropoff_address) {
        getServiceId(value, dropoff_id, pickup_country, destination_country);
      };
    };
  };

  function onDropOffAddressChange(value) {
    setSelected_dropoff_address(value);
    const selectedDropOffData = _.find(dropOff_data, x => x.dropoff_address === value);
    getServiceId(service_type, selectedDropOffData.dropoff_id, pickup_country, destination_country);
  };

  function getServiceId(serviceType, dropoff_id, pickup_country, destination_country) {
    setServiceTypeLoading(true);
    const data = {
      secret_key: SECRET_KEY_JANIO_API,
      pickup_country: pickup_country,
      destination_country: destination_country,
      service_type: serviceType.toLowerCase()
    };
    if (serviceType === 'Dropoff') {
      data.dropoff_id = dropoff_id;
    };
    axios.post(`${JANIO_MAIN_API_URL}/order/get-service-id/`, data)
      .then((res) => {
        if (res.status === 200 && res.data.message) {
          setServiceTypeLoading(false);
          setServiceTypeError(res.data.message);
          return;
        }
        setService_type(serviceType);
        setService_id(res.data.service_id);
        setServiceTypeError(null);
        setServiceTypeLoading(false);
        setDropoff_id(dropoff_id);
      }).catch((err) => {
        const error = err.response.data.service_type ? err.response.data.service_type[0] : err.response.data.message ? err.response.data.message[0] : 'An error occurred.Please try again';
        setService_type(null);
        setService_id(null);
        setSelected_dropoff_address(null);
        setServiceTypeError(error);
        setServiceTypeLoading(false);
        setDropoff_id(null);
        setDropoff_addresses([]);
        setDropOff_data([]);
      })
  }

  function getDropOffAddresses(origin, destination) {
    return serviceDefinitions
      .filter(
        def => {
          const serviceType = def.service_type.toLowerCase() || ''
          return (
            origin === def.origin_country
            && destination === def.destination_country
            && !serviceType.includes('return')
          )
        }
      )
      .map(serviceDef => {
        return { dropoff_id: serviceDef.dropoff_id, dropoff_address: serviceDef.dropoff_address };
      });
  };

  function getDropOffId(origin, destination) {
    return serviceDefinitions
      .filter(
        def => {
          const serviceType = def.service_type.toLowerCase() || ''
          return (
            origin === def.origin_country
            && destination === def.destination_country
            && !serviceType.includes('return')
          )
        }
      )
      .map(serviceDef => {
        return { dropoff_id: serviceDef.dropoff_id, dropoff_address: serviceDef.dropoff_address }
      });
  };

  function getServiceTypeOptions(origin, destination) {
    return serviceDefinitions
      .filter(
        def => {
          const serviceType = def.service_type.toLowerCase() || ''
          return (
            origin === def.origin_country
            && destination === def.destination_country
            && !serviceType.includes('return')
          )
        }
      )
      .map(serviceDef => serviceDef.service_type)
      .filter(
        (value, index, self) => self.indexOf(value) === index
      )
      .map(
        serviceType => (serviceType)
      );
  }
}
