import React from 'react';

const RequestInputs = (props) => {
  return (
    <div className="doc-content__inputs">
      <h4 className="font-weight-bold">{props.title}</h4>
      <h6 className='text-muted mb-4'>Enter fields to test API</h6>
      {props.children}
    </div>
  )
}

export default RequestInputs