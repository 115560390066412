import React from "react";

import NavBar from "./../components/UI/navigation/navbar";

const Main = (props) => {
  return (
    <div className="row">
      <NavBar toggleSidebar={props.toggleSidebar} />
      {props.children}
    </div>
  );
};

export default Main;
