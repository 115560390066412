import React from 'react';
import { Table } from 'react-bootstrap';

const renderExtra = (extraDetails) => (
  extraDetails.map(extra => (
    <div>
      <p className='text-muted d-inline my-0' style={{fontSize:14}}>{extra.prop}: </p>
      <p className='text-muted d-inline my-0' style={{fontSize:14}}>{extra.value}</p>
    </div>
  ))
)

const RequestParams = (props) => (
  <>
  <p className='font-weight-bold text-left ml-2 mt-4'>Query Parameters</p>
    <Table>
        <tbody>
          {props.params.map(row=>(
            <tr key={row.title} className='d-flex'>
              <td className='col-md-4 text-left'>
                <p className='font-weight-bold my-0 d-inline'>{row.title}</p>
                {row.required && <p className='text-danger d-inline' style={{fontSize:14}}> * required</p>}
                <p className='text-primary my-1'>{row.type}</p>
                {row.extra ? renderExtra(row.extra) : null}
              </td>
              <td className='col-md-8 text-left'>
                <h6>{row.desc}</h6>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>  
  <p/>
  </>
)

export default RequestParams