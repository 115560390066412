import React from 'react';
import { Form } from 'react-bootstrap';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";


const stdInput = (props) => {

  let requiredText = <p className='d-inline' style={{color:'red'}}>*</p>
  let formControl = <Form.Control className='mt-1' type={props.type} required placeholder={props.placeholder} onChange={props.change} value={props.value} disabled={props.disabled}/>
  if (props.optional){
    let optionalText = props.optional === true ? '(Optional)' : props.optional
    requiredText = <Form.Text className='d-inline text-muted'>{optionalText}</Form.Text>;
    formControl = <Form.Control className='mt-1' type={props.type} placeholder={props.placeholder} onChange={props.change} value={props.value} disabled={props.disabled}/>;
  };
  if (props.select) {
    formControl = (
    <Form.Control as="select" onChange={props.change} value={props.value} disabled={props.disabled}>
      {props.options.map(row=>(
        <option key={row}>{row}</option>
      ))}
    </Form.Control>)
  };
  if (props.datetime) {
    formControl = (
      <div className='mt-1'>
        <DatePicker className='w-100'
          disabled={props.disabled} 
          onChange={props.change}
          dateFormat="YYYY-MM-dd'T'h:mm:ss.sssZ"
          selected={props.selected}
          isClearable={props.optional}
          placeholderText={props.placeholder}/>
      </div>)
  }
  if (props.date) {
    formControl = (
        <DatePicker className='w-100'
          disabled={props.disabled} 
          style={{width:'100% !important'}}
          onChange={props.change}
          dateFormat="dd-MM-YYYY"
          selected={props.selected}
          isClearable={false}
          placeholderText={props.placeholder}/>)
  }
  let label = props.noLabel 
    ? <Form.Label className='d-block text-left mb-0'>{props.label} :</Form.Label>
    : <Form.Label className='d-block text-left mb-0'>{props.label} {requiredText} :</Form.Label>
  return (
    <Form.Group className='w-100 d-flex flex-column align-items-start' controlId="formBasicEmail">
      {label}
      <Form.Text className="d-block text-left text-muted mt-0" style={{fontSize: 15}}>
      <div className='d-block'>
        {props.extra}
      </div>
      </Form.Text>
      {formControl}
    </Form.Group>
  );
};


export default stdInput;