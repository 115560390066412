import React from 'react';
import { Badge } from 'react-bootstrap';
import TerminalText from '../terminalText/terminal';

const renderExamples = (responseExamples) => (
  responseExamples.map(responseData => (
    <React.Fragment>
      <p className='text-left mt-3' key={responseData.status}>
        {responseData.status} <Badge variant={responseData.badgeVariant}>{responseData.statusCode}</Badge> 
      </p>
      <TerminalText text={responseData.terminalText}/>
    </React.Fragment>
    )
  )
)

const ResponseExample = (props) => {
  return (
    <div className='request-body'>
      <h5 className="font-weight-bold text-left">Response Example</h5>
      {renderExamples(props.examples)}
    </div>
  )
}

export default ResponseExample;