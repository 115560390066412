import React, { Component } from 'react';
import { Button, InputGroup, FormControl, Form, Alert } from 'react-bootstrap';
import axios from 'axios';

import {ROOT_URL} from '../utils/urls';
import Input from '../components/UI/input/stdInput';
import { publicStatusV2 } from '../utils/data';

class TrackingSimulator extends Component {

  state = {
    loading: false,
    hasSecretKey: localStorage.getItem('secretKey') ? true : false,
    fetchingWebhook: false,
    fetchingWebhookError: false,
    changeTrackerSubmitted: false,
    settingWebhook: false,
    settingWebhookError: false,
    webhookSet: false,
    webhookURL: '',
    agentAppEmail: '',
    changeTracking: {
      secret_key: localStorage.getItem('secretKey') ? localStorage.getItem('secretKey') : '',
      tracking_status: 'ORDER_CREATED',
      tracking_nos: ''
    }
  }

  componentDidMount = () => {
    const datePickerWrapperList = document.querySelectorAll('.react-datepicker-wrapper');
    datePickerWrapperList.forEach((item) => {
      item.style.display = 'block';
    });

    const datePickerList = document.querySelectorAll('.react-datepicker__input-container');
    datePickerList.forEach((item) => {
      item.style.display = 'block';
    });
    
    // only fetch webhook info initially if secret key exists in local storage
    if (this.state.hasSecretKey){
      this.setState({fetchingWebhook: true})
      axios.get(ROOT_URL + '/admin/configure-account/?secret_key='+ localStorage.getItem('secretKey'))
        .then(response => {
          this.setState({webhookURL: response.data.webhook_url})
          this.setState({agentAppEmail: response.data.agent_application_name})
          this.setState({fetchingWebhookError: false})
          this.setState({fetchingWebhook: false})
        }).catch(error => {
          this.setState({fetchingWebhookError: true})
          this.setState({fetchingWebhook: false})
        });
    };
  };

  inputChangedHandler = (event, inputIdentifier) => {
    
    const updatedTracking = {
      ...this.state.changeTracking
    }
    if (inputIdentifier === 'webhookURL'){
      this.setState({webhookSet: false})
      this.setState({webhookURL: event.target.value})
    };
    updatedTracking[inputIdentifier] = event.target.value;
    this.setState({changeTracking: updatedTracking})
    this.setState({changeTrackerSubmitted: false})
    this.setState({changeTrackerError: false})
  };

  handleDateChanged = (date) => {
    
    const updatedQueries = {
      ...this.state.changeTracking
    }
    
    updatedQueries['updated_on'] = date;
    this.setState({changeTracking: updatedQueries})
    this.setState({changeTrackerSubmitted: false})
    this.setState({changeTrackerError: false})
  };

  changeTracker = () => {
    this.setState({loading: true})
    const payload = {
      ...this.state.changeTracking
    }
    payload['tracking_nos'] = payload['tracking_nos'].replace(' ','').split(',')
    
    
    axios.post(ROOT_URL + '/tracker/change-tracker-status/', payload)
        .then(response => {
          console.log(response)
          localStorage.setItem('secretKey', this.state.changeTracking.secret_key)
          this.setState({changeTrackerSubmitted: true})

          this.setState({loading: false})
        }).catch(error => {
          this.setState({changeTrackerError: true})
          this.setState({loading: false})
        });
  };

  submitSecretKey = () => {
    this.setState({fetchingWebhook: true})
    this.setState({hasSecretKey: true})

    axios.get(ROOT_URL + '/admin/configure-account/?secret_key='+ this.state.changeTracking.secret_key)
        .then(response => {
          console.log(response)
          localStorage.setItem('secretKey', this.state.changeTracking.secret_key)
          this.setState({webhookURL: response.data.webhook_url})
          this.setState({agentAppEmail: response.data.agent_application_name})
          this.setState({fetchingWebhook: false})
          this.setState({fetchingWebhookError: false})
        }).catch(error => {
          this.setState({fetchingWebhookError: true})
          this.setState({fetchingWebhook: false})
          this.setState({hasSecretKey: false})
        });
  };

  setWebhook = () => {
    this.setState({settingWebhook: true})
    this.setState({webhookSet: false})
    const payload = {
      secret_key: this.state.changeTracking.secret_key,
      webhook_url: this.state.webhookURL,
      agent_application_contact_person: this.state.agentAppEmail,
      agent_application_name: this.state.agentAppEmail,
      agent_application_number: 'a',
      billing_address: 'a',
      billing_company_name: this.state.agentAppEmail,
      billing_country: 'a',
      billing_email: 'a@email.com',
      company_registration_number: 'a',
      language: 'eng'
    }
    axios.post(ROOT_URL + '/admin/configure-account/', payload)
        .then(response => {
          this.setState({settingWebhookError: false})
          this.setState({settingWebhook: false})
          this.setState({webhookSet: true})
        }).catch(error => {
          this.setState({settingWebhookError: true})
          this.setState({settingWebhook: false})
        });
  };

  componentDidUpdate = () => {
    const element = document.getElementById("changeTrackingStatusContent");
    if (element  && this.state.changeTrackerSubmitted) {
      element.scrollIntoView({behavior: "smooth"});
    }
  }

  render() {

    let setWebhookButton = this.state.settingWebhook
      ? <Button variant="outline-secondary"  disabled onClick={this.setWebhook}>setting webhook...</Button>
      : <Button variant="outline-secondary" onClick={this.setWebhook}>Set Webhook</Button>

    let webhookButton = this.state.fetchingWebhook
      ? <Button disabled variant="outline-secondary">fetching webhook info...</Button>
      : setWebhookButton

    let changeStatusButton = this.state.loading
      ? <Button onClick={this.changeTracker} disabled className='pt-2 mb-2'><h5>changing status...</h5></Button>
      : <Button onClick={this.changeTracker} className='pt-2 mb-2'><h5>Change Status</h5></Button>

    let hasSecretKeyContent = this.state.hasSecretKey 
      ? (
        <div>
          <Form.Label className='float-left mb-0'>Webhook URL <Form.Text className='d-inline text-muted'>(Optional)</Form.Text> :</Form.Label>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Currently no webhook set"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              value={this.state.webhookURL} 
              onChange={(event) => this.inputChangedHandler(event,'webhookURL')}
            />  
            <InputGroup.Append>
              {webhookButton}
            </InputGroup.Append>
          </InputGroup>
        </div>
      )
      : (
        <div>
          <Form.Label className='float-left mb-0'>Secret Key <p className='text-danger d-inline'>*</p> :</Form.Label>
          <InputGroup className="mb-3">
            <FormControl
              placeholder="Enter secret key to access webhook information"
              aria-label="Recipient's username"
              aria-describedby="basic-addon2"
              value={this.state.changeTracking.secret_key} 
              onChange={(event) => this.inputChangedHandler(event,'secret_key')}
            />  
            <InputGroup.Append>
              <Button onClick={this.submitSecretKey} variant="outline-secondary">Enter Secret Key</Button>
            </InputGroup.Append>
          </InputGroup>
        </div>
      )

      let webhookContent = this.state.webhookURL
        ? <p>A webhook notification will be posted to <em>{this.state.webhookURL}</em> with the following payload:</p>
        : null

      let changeTrackingStatusContent = this.state.changeTrackerSubmitted
        ? (<Alert id='changeTrackingStatusContent' variant='success'>Orders with tracking numbers <strong>{this.state.changeTracking.tracking_nos}</strong> have been changed to status - <strong>{this.state.changeTracking.tracking_status}</strong>.{webhookContent}
            <div className='bg-dark rounded-2 border mx-2 mt-2 align-left'>
              <pre style={{color:'#67D62B'}} className='text-left p-2'>{`{
    "update_type": "tracking",
    "data": {
      "order_id": "< Janio Order Identifier>",
      "tracking_no": "${this.state.changeTracking.tracking_nos.split(",")[0]}",
      "status": "${this.state.changeTracking.tracking_status}",
      "updated_on": "${this.state.changeTracking.updated_on ?this.state.changeTracking.updated_on.toISOString() : (new Date()).toISOString()}"
      }
}`}</pre>
            </div>
          </Alert>)
        : null

      let secretKeyError = this.state.fetchingWebhookError
        ? <Alert variant='danger'>Unable to fetch webhook information, please ensure correct secret key is provided.</Alert>
        : null

      let webhookError = this.state.settingWebhookError
        ? <Alert variant='danger'>Unable to set webhook, ensure that a valid `https` or `http` endpoint was provided.</Alert>
        : null

      let webhookSuccess = this.state.webhookSet
        ? <Alert variant='success'>Webhook has been successfully set to {this.state.webhookURL}.</Alert>
        : null

    return (
      <article className="col-lg-10 col-xxl-11 doc-content--center">
          <h1>Simulate Tracking Status Change</h1>
          <div>
            <div className='border border-info card px-5 py-3 w-60 my-3'>
              <h5>Webhook Notification</h5>
              <p>
                Input a webhook url to receive updates when the tracking statuses of your orders change.
                <br/>
                A temporary webhook endpoint can be generated at <a target='blank' href='https://www.webhook.site'>webhook.site</a>
              </p>
              {secretKeyError}
              {webhookError}
              {webhookSuccess}
              {hasSecretKeyContent}
            </div>
            <div className='border border-info card px-5 py-3 w-60 mt-4 mb-0'>
              <h5>Change Tracking Status</h5>
              <p className='text-info'>
                *Feature to change tracking status is only available in development for testing purposes.
              </p>
              <Input label='Secret Key' value={this.state.changeTracking.secret_key} change={(event) => this.inputChangedHandler(event,'secret_key')} placeholder='Unique key supplied by Janio'/>
              <Input label='Tracking Numbers' type='text' placeholder='e.g. trackingnumber1,trackingnumber2' 
                extra='If entering multiple tracking numbers, separate them with `,`' change={(event) => this.inputChangedHandler(event,'tracking_nos')} value={this.state.changeTracking.tracking_nos}/>
              <Input select options={publicStatusV2} change={(event) => this.inputChangedHandler(event,'tracking_status')} label='Tracking Status'/>
              <Input label='Updated On' placeholder="If left blank default will be set to today's date" optional datetime change={this.handleDateChanged} selected={this.state.changeTracking.updated_on}/>
              {changeTrackingStatusContent}
              {this.state.changeTrackerError && <Alert variant='danger'>Unable to change tracking status, ensure that input fields are properly filled.</Alert>}
              {changeStatusButton}
            </div>
          </div>
        </article>
    );
  }
}

export default TrackingSimulator;