import React from 'react';
import { useState, useEffect } from 'react'
import axios from 'axios'
import { Form } from 'react-bootstrap';

const formatService = (service) => {

  let destination = service.service_destination_country
  if (service.service_destination_country.includes(',')) {
    destination = /to ([^([]+) ([([])/.exec(service.service_name)[1]
  }
  return `${service.service_id}: ${service.service_origin_city}-${destination} (${service.service_category})`
}

const ServicesDropdown = (props) => {
  const secretKey = 'apidocs'
  const [isServiceLoading, setLoading] = useState(true)
  const [services, setServices] = useState([])

  useEffect(() => {
    const getServices = async () => {

    const response = await axios.get(`https://api.janio.asia/api/order/service/`,
        {
        params: {
            secret_key: secretKey,
        }
        }
    )
    setServices(response.data)
    setLoading(false)
    }
    getServices()
  }, []);
  let label = (
    <Form.Label className='d-block text-left mb-0'>
      <p className='d-inline'>Service </p>
      {props.optional 
        ? <Form.Text className='d-inline text-muted' style={{color:'red'}}>(Optional)</Form.Text> 
        :<p className='d-inline' style={{color:'red'}}>*</p>}
      <p className='d-inline'> :</p>
    </Form.Label>
  )
  let formControl = (
    isServiceLoading 
    ? (
    <Form.Control as="select" onChange={props.change} value={props.value}>
      <option>loading services..</option>
    </Form.Control>
    )
    :(
      <Form.Control as="select" onChange={props.change} value={props.value}>
        {services.map(service => {

          return <option>{formatService(service)}</option>
        })}
      </Form.Control>
    ))
  
  return (
    <Form.Group className='w-100 d-flex flex-column align-items-start' controlId="formBasicEmail">
      {label}
      <Form.Text className="d-block text-left text-muted mt-1" style={{fontSize: 15}}>
      </Form.Text>
      {formControl}
    </Form.Group>
  );

}

export default ServicesDropdown